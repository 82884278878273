@use './typography/typography';
@use './typography/_classNames.scss';
@use './layout/_spacings.scss';
@use './layout/_layout.scss';
@use './color/color.scss';

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  @include typography.chat-text;
  color: var(--charcoalToWhite);
  background-color: var(--whiteToCharcoal);
  overflow: hidden;
}

@media (max-width: 768px) {
  * {
    overflow-x: hidden;
  }
}

.sr-only {
  @include layout.sr-only;
}

.sm-only {
  display: block;
  @include layout.md {
    display: none;
  }
}

.lg-only {
  display: none;
  @include layout.lg {
    display: block;
  }
}

* {
  // Custom scrollbar styles
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 11px;
    border: 2px solid transparent;
    background-clip: content-box;
  }
  &:hover::-webkit-scrollbar-thumb {
    background-color: var(--lightCoolGrayToLightCharcoal);
  }

  // Standard scrollbar styles
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;

  &:hover {
    scrollbar-color: var(--lightCoolGrayToLightCharcoal) transparent;
  }
  
  // Transition for scrollbar color
  transition: scrollbar-color linear 200ms;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

/* Euclid Circular A */
/* Light */
@font-face {
  font-family: 'Euclid Circular A';
  src: url('./fonts/euclid/light.woff2') format('woff2'), url('./fonts/euclid/light.woff') format('woff');
  font-weight: 300;
}
/* Regular */
@font-face {
  font-family: 'Euclid Circular A';
  src: url('./fonts/euclid/regular.woff2') format('woff2'), url('./fonts/euclid/regular.woff') format('woff');
  font-weight: 400;
}
/* Medium */
@font-face {
  font-family: 'Euclid Circular A';
  src: url('./fonts/euclid/medium.woff2') format('woff2'), url('./fonts/euclid/medium.woff') format('woff');
  font-weight: 500;
}
/* Semibold */
@font-face {
  font-family: 'Euclid Circular A';
  src: url('./fonts/euclid/semibold.woff2') format('woff2'), url('./fonts/euclid/semibold.woff') format('woff');
  font-weight: 600;
}
/* Bold */
@font-face {
  font-family: 'Euclid Circular A';
  src: url('./fonts/euclid/bold.woff2') format('woff2'), url('./fonts/euclid/bold.woff') format('woff');
  font-weight: 700;
}

/* Feature Display */

/* Medium */
@font-face {
  font-family: 'Feature Display Web';
  src: url('./fonts/feature-display/medium.woff2') format('woff2'),
    url('./fonts/feature-display/medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}


/* ExtraBold */
@font-face {
  font-family: 'Feature Display Web';
  src: url('./fonts/feature-display/extrabold.woff2') format('woff2'),
    url('./fonts/feature-display/extrabold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
}

/* Acid Grotesk */

/* Medium */
@font-face {
  font-family: 'Acid Grotesk';
  src: url('./fonts/acid-grotesk/medium.woff2') format('woff2'), url('./fonts/acid-grotesk/medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

/* Suisse Intl */
/* Regular */
@font-face {
  font-family: 'Suisse Intl';
  src: url('./fonts/suisse/regular.woff2') format('woff2'), url('./fonts/suisse/regular.woff') format('woff');
  font-weight: 400;
}
/* Medium */
@font-face {
  font-family: 'Suisse Intl';
  src: url('./fonts/suisse/medium.woff2') format('woff2'), url('./fonts/suisse/medium.woff') format('woff');
  font-weight: 500;
}
/* Semibold */
@font-face {
  font-family: 'Suisse Intl';
  src: url('./fonts/suisse/semibold.woff2') format('woff2'), url('./fonts/suisse/semibold.woff') format('woff');
  font-weight: 600;
}
/* Bold */
@font-face {
  font-family: 'Suisse Intl';
  src: url('./fonts/suisse/bold.woff2') format('woff2'), url('./fonts/suisse/bold.woff') format('woff');
  font-weight: 700;
}
/* Regular Italic */
@font-face {
  font-family: 'Suisse Intl';
  src: url('./fonts/suisse/regular-italic.woff2') format('woff2'),
    url('./fonts/suisse/regular-italic.woff') format('woff');
  font-style: italic;
}
/* Semibold Italic */
@font-face {
  font-family: 'Suisse Intl';
  src: url('./fonts/suisse/semibold-italic.woff2') format('woff2'),
    url('./fonts/suisse/semibold-italic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
}

/* Regular Mono */
@font-face {
  font-family: 'Suisse Intl Mono';
  src: url('./fonts/suisse/mono.woff2') format('woff2'), url('./fonts/suisse/mono.woff') format('woff');
  font-style: normal;
}

main {
  height: 100%;
}
