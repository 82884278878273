$colors: (
  pink: (
    dark: (
      primarycolor: #f7468a,
      shadeone: #c5386e,
      shadetwo: #942a53,
      shadethree: #631c37,
      shadefour: #310e1c,
      highlightcolor: #f7468a,
      buttontextcolor: #ffffff,
    ),
    light: (
      primarycolor: #f7468a,
      shadeone: #f96ba1,
      shadetwo: #ffaac6,
      shadethree: #fddae8,
      shadefour: #feedf3,
      highlightcolor: #f7468a,
      buttontextcolor: #ffffff,
    ),
  ),
  blue: (
    dark: (
      primarycolor: #008cff,
      shadeone: #0070cc,
      shadetwo: #005499,
      shadethree: #003866,
      shadefour: #001c33,
      highlightcolor: #008cff,
      buttontextcolor: #ffffff,
    ),
    light: (
      primarycolor: #008cff,
      shadeone: #4daeff,
      shadetwo: #90c7f2,
      shadethree: #cce8ff,
      shadefour: #e6f4ff,
      highlightcolor: #008cff,
      buttontextcolor: #ffffff,
    ),
  ),
  orange: (
    dark: (
      primarycolor: #f45d22,
      shadeone: #c34a1b,
      shadetwo: #923814,
      shadethree: #62250e,
      shadefour: #311307,
      highlightcolor: #f45d22,
      buttontextcolor: #ffffff,
    ),
    light: (
      primarycolor: #f45d22,
      shadeone: #f67d4e,
      shadetwo: #f3a688,
      shadethree: #fddfd3,
      shadefour: #feefe9,
      highlightcolor: #f45d22,
      buttontextcolor: #ffffff,
    ),
  ),

  black: (
    dark: (
      primarycolor: #eeeff5,
      shadeone: #bebfc4,
      shadetwo: #8f8f93,
      shadethree: #5f6062,
      shadefour: #262729,
      highlightcolor: #008cff,
      buttontextcolor: #2a2d35,
    ),
    light: (
      primarycolor: #2a2d35,
      shadeone: #55575d,
      shadetwo: #7f8186,
      shadethree: #d4d5d7,
      shadefour: #eaeaeb,
      highlightcolor: #008cff,
      buttontextcolor: #ffffff,
    ),
  ),

  purple: (
    dark: (
      primarycolor: #794cc3,
      shadeone: #613d9c,
      shadetwo: #492e75,
      shadethree: #301e4e,
      shadefour: #180f27,
      highlightcolor: #794cc3,
      buttontextcolor: #ffffff,
    ),
    light: (
      primarycolor: #794cc3,
      shadeone: #9470cf,
      shadetwo: #b39dd7,
      shadethree: #e4dbf3,
      shadefour: #f2edf9,
      highlightcolor: #794cc3,
      buttontextcolor: #ffffff,
    ),
  ),
  green: (
    dark: (
      primarycolor: #007f03,
      shadeone: #006602,
      shadetwo: #004c02,
      shadethree: #003301,
      shadefour: #001901,
      highlightcolor: #007f03,
      buttontextcolor: #ffffff,
    ),
    light: (
      primarycolor: #007f03,
      shadeone: #339935,
      shadetwo: #78ad7a,
      shadethree: #cce5cd,
      shadefour: #e6f2e6,
      highlightcolor: #007f03,
      buttontextcolor: #ffffff,
    ),
  ),
);

@function get-color($color, $mode: 'dark', $value: 'primaryColor') {
  @return map-get(map-get(map-get($colors, $color), $mode), $value);
}

.color-scheme-Pink {
  --primary: #{get-color(pink, light, primarycolor)};
  --shadeOne: #{get-color(pink, light, shadeone)};
  --shadeTwo: #{get-color(pink, light, shadetwo)};
  --shadeThree: #{get-color(pink, light, shadethree)};
  --shadeFour: #{get-color(pink, light, shadefour)};
  --hightlightcolor: #{get-color(pink, light, highlightcolor)};
  --buttontextcolor: #{get-color(pink, light, buttontextcolor)};
  @media (prefers-color-scheme: dark) {
    --primary: #{get-color(pink, dark, primarycolor)};
    --shadeOne: #{get-color(pink, dark, shadeone)};
    --shadeTwo: #{get-color(pink, dark, shadetwo)};
    --shadeThree: #{get-color(pink, dark, shadethree)};
    --shadeFour: #{get-color(pink, dark, shadefour)};
    --hightlightcolor: #{get-color(pink, dark, highlightcolor)};
    --buttontextcolor: #{get-color(pink, dark, buttontextcolor)};
  }
}

.color-scheme-Blue {
  --primary: #{get-color(blue, light, primarycolor)};
  --shadeOne: #{get-color(blue, light, shadeone)};
  --shadeTwo: #{get-color(blue, light, shadetwo)};
  --shadeThree: #{get-color(blue, light, shadethree)};
  --shadeFour: #{get-color(blue, light, shadefour)};
  --hightlightcolor: #{get-color(blue, light, highlightcolor)};
  --buttontextcolor: #{get-color(blue, light, buttontextcolor)};
  @media (prefers-color-scheme: dark) {
    --primary: #{get-color(blue, dark, primarycolor)};
    --shadeOne: #{get-color(blue, dark, shadeone)};
    --shadeTwo: #{get-color(blue, dark, shadetwo)};
    --shadeThree: #{get-color(blue, dark, shadethree)};
    --shadeFour: #{get-color(blue, dark, shadefour)};
    --hightlightcolor: #{get-color(blue, dark, highlightcolor)};
    --buttontextcolor: #{get-color(blue, dark, buttontextcolor)};
  }
}

.color-scheme-Orange {
  --primary: #{get-color(orange, light, primarycolor)};
  --shadeOne: #{get-color(orange, light, shadeone)};
  --shadeTwo: #{get-color(orange, light, shadetwo)};
  --shadeThree: #{get-color(orange, light, shadethree)};
  --shadeFour: #{get-color(orange, light, shadefour)};
  --hightlightcolor: #{get-color(orange, light, highlightcolor)};
  --buttontextcolor: #{get-color(orange, light, buttontextcolor)};
  @media (prefers-color-scheme: dark) {
    --primary: #{get-color(orange, dark, primarycolor)};
    --shadeOne: #{get-color(orange, dark, shadeone)};
    --shadeTwo: #{get-color(orange, dark, shadetwo)};
    --shadeThree: #{get-color(orange, dark, shadethree)};
    --shadeFour: #{get-color(orange, dark, shadefour)};
    --hightlightcolor: #{get-color(orange, dark, highlightcolor)};
    --buttontextcolor: #{get-color(orange, dark, buttontextcolor)};
  }
}

.color-scheme-Black {
  --primary: #{get-color(black, light, primarycolor)};
  --shadeOne: #{get-color(black, light, shadeone)};
  --shadeTwo: #{get-color(black, light, shadetwo)};
  --shadeThree: #{get-color(black, light, shadethree)};
  --shadeFour: #{get-color(black, light, shadefour)};
  --hightlightcolor: #{get-color(black, light, highlightcolor)};
  --buttontextcolor: #{get-color(black, light, buttontextcolor)};
  @media (prefers-color-scheme: dark) {
    --primary: #{get-color(black, dark, primarycolor)};
    --shadeOne: #{get-color(black, dark, shadeone)};
    --shadeTwo: #{get-color(black, dark, shadetwo)};
    --shadeThree: #{get-color(black, dark, shadethree)};
    --shadeFour: #{get-color(black, dark, shadefour)};
    --hightlightcolor: #{get-color(black, dark, highlightcolor)};
    --buttontextcolor: #{get-color(black, dark, buttontextcolor)};
  }
}

.color-scheme-Purple {
  --primary: #{get-color(purple, light, primarycolor)};
  --shadeOne: #{get-color(purple, light, shadeone)};
  --shadeTwo: #{get-color(purple, light, shadetwo)};
  --shadeThree: #{get-color(purple, light, shadethree)};
  --shadeFour: #{get-color(purple, light, shadefour)};
  --hightlightcolor: #{get-color(purple, light, highlightcolor)};
  --buttontextcolor: #{get-color(purple, light, buttontextcolor)};
  @media (prefers-color-scheme: dark) {
    --primary: #{get-color(purple, dark, primarycolor)};
    --shadeOne: #{get-color(purple, dark, shadeone)};
    --shadeTwo: #{get-color(purple, dark, shadetwo)};
    --shadeThree: #{get-color(purple, dark, shadethree)};
    --shadeFour: #{get-color(purple, dark, shadefour)};
    --hightlightcolor: #{get-color(purple, dark, highlightcolor)};
    --buttontextcolor: #{get-color(purple, dark, buttontextcolor)};
  }
}

.color-scheme-Green {
  --primary: #{get-color(green, light, primarycolor)};
  --shadeOne: #{get-color(green, light, shadeone)};
  --shadeTwo: #{get-color(green, light, shadetwo)};
  --shadeThree: #{get-color(green, light, shadethree)};
  --shadeFour: #{get-color(green, light, shadefour)};
  --hightlightcolor: #{get-color(green, light, highlightcolor)};
  --buttontextcolor: #{get-color(green, light, buttontextcolor)};
  @media (prefers-color-scheme: dark) {
    --primary: #{get-color(green, dark, primarycolor)};
    --shadeOne: #{get-color(green, dark, shadeone)};
    --shadeTwo: #{get-color(green, dark, shadetwo)};
    --shadeThree: #{get-color(green, dark, shadethree)};
    --shadeFour: #{get-color(green, dark, shadefour)};
    --hightlightcolor: #{get-color(green, dark, highlightcolor)};
    --buttontextcolor: #{get-color(green, dark, buttontextcolor)};
  }
}

$black: '#000000';
$darkCharcoal: '#1A1C21';
$charcoal: '#2A2D35';
$lightCharcoal: '#606272';
$coolGray: '#888AA0';
$lightCoolGray: '#C3C4CF';
$featherGray: '#DEDFEB';
$fogGray: '#EEEFF5';
$lightFogGray: '#F5F5F9';
$white: '#FFFFFF';
$genevaGreen: '#007F03';
$genevaBlue: '#92CEFF';
$focusBlue: '#48ACFF';
$primaryBlue: '#008CFF';
$primaryOrange: '#F45D22';
$primaryPink: '#F7468A';
$primaryPurple: '#794CC3';
$pending: '#F2C94C';
$error: '#E0244D';
$danger: '#E02020';
$offlineBlue: '#92CEFF';

.text-coolgray {
  color: var(--coolGray);
}
.text-whiteToBlack {
  color: var(--whiteToBlack);
}

:root {
  --offlineBlue: #{$offlineBlue};
  --black: #{$black};
  --darkCharcoal: #{$darkCharcoal};
  --charcoal: #{$charcoal};
  --lightCharcoal: #{$lightCharcoal};
  --coolGray: #{$coolGray};
  --lightCoolGray: #{$lightCoolGray};
  --featherGray: #{$featherGray};
  --fogGray: #{$fogGray};
  --lightFogGray: #{$lightFogGray};
  --white: #{$white};
  --genevaGreen: #{$genevaGreen};
  --genevaBlue: #{$genevaBlue};
  --focusBlue: #{$focusBlue};
  --primaryBlue: #{$primaryBlue};
  --primaryOrange: #{$primaryOrange};
  --primaryPink: #{$primaryPink};
  --primaryPurple: #{$primaryPurple};
  --pending: #{$pending};
  --error: #{$error};
  --danger: #{$danger};
  --highlighter: #d7ff1d;
  --highlighterShadeOne: #d7ff1d80;
  --blackToWhite: #{$black};
  --charcoalToCoolGray: #{$charcoal};
  --charcoalToFeatherGray: #{$charcoal};
  --charcoalToFogGray: #{$charcoal};
  --charcoalToLightFogGray: #{$charcoal};
  --charcoalToWhite: #{$charcoal};
  --coolGrayToCharcoal: #{$coolGray};
  --coolGrayToFeatherGray: #{$coolGray};
  --coolGrayToLightCharcoal: #{$coolGray};
  --coolGrayToLightFogGray: #{$coolGray};
  --darkCharcoalToLightFogGray: #{$darkCharcoal};
  --featherGrayToCharcoal: #{$featherGray};
  --featherGrayToCoolGray: #{$featherGray};
  --featherGrayToDarkCharcoal: #{$featherGray};
  --featherGrayToFogGray: #{$featherGray};
  --featherGrayToLightCharcoal: #{$featherGray};
  --fogGrayToCharcoal: #{$fogGray};
  --fogGrayToDarkCharcoal: #{$fogGray};
  --fogGrayToLightCharcoal: #{$fogGray};
  --fogGrayToLightFogGray: #{$fogGray};
  --lightCharcoalToFeatherGray: #{$lightCharcoal};
  --lightCharcoalToLightCharcoal: #{$lightCharcoal};
  --lightCharcoalToLightCoolGray: #{$lightCharcoal};
  --lightCoolGrayToCharcoal: #{$lightCoolGray};
  --lightCoolGrayToLightCharcoal: #{$lightCoolGray};
  --lightCoolGrayToLightCoolGray: #{$lightCoolGray};
  --lightFogGrayToBlack: #{$lightFogGray};
  --lightFogGrayToCharcoal: #{$lightFogGray};
  --lightFogGrayToDarkCharcoal: #{$lightFogGray};
  --whiteToBlack: #{$white};
  --whiteToCharcoal: #{$white};
  --whiteToDarkCharcoal: #{$white};
  --whiteToFogGray: #{$white};
  --whiteToLightCharcoal: #{$white};
  main:not(.disable-dark) {
    @media (prefers-color-scheme: dark) {
      --blackToWhite: #{$white};
      --charcoalToCoolGray: #{$coolGray};
      --charcoalToFeatherGray: #{$featherGray};
      --charcoalToFogGray: #{$fogGray};
      --charcoalToLightFogGray: #{$lightFogGray};
      --charcoalToWhite: #{$white};
      --coolGrayToCharcoal: #{$charcoal};
      --coolGrayToFeatherGray: #{$featherGray};
      --coolGrayToLightCharcoal: #{$lightCharcoal};
      --coolGrayToLightFogGray: #{$lightFogGray};
      --darkCharcoalToLightFogGray: #{$lightFogGray};
      --featherGrayToCharcoal: #{$charcoal};
      --featherGrayToCoolGray: #{$coolGray};
      --featherGrayToDarkCharcoal: #{$darkCharcoal};
      --featherGrayToFogGray: #{$fogGray};
      --featherGrayToLightCharcoal: #{$lightCharcoal};
      --fogGrayToCharcoal: #{$charcoal};
      --fogGrayToDarkCharcoal: #{$darkCharcoal};
      --fogGrayToLightCharcoal: #{$lightCharcoal};
      --fogGrayToLightFogGray: #{$lightFogGray};
      --lightCharcoalToFeatherGray: #{$featherGray};
      --lightCharcoalToLightCoolGray: #{$lightCoolGray};
      --lightCoolGrayToCharcoal: #{$charcoal};
      --lightCoolGrayToLightCharcoal: #{$lightCharcoal};
      --lightCoolGrayToLightCoolGray: #{$lightCoolGray};
      --lightFogGrayToBlack: #{$black};
      --lightFogGrayToCharcoal: #{$charcoal};
      --lightFogGrayToDarkCharcoal: #{$darkCharcoal};
      --whiteToBlack: #{$black};
      --whiteToCharcoal: #{$charcoal};
      --whiteToDarkCharcoal: #{$darkCharcoal};
      --whiteToFogGray: #{$fogGray};
      --whiteToLightCharcoal: #{$lightCharcoal};
    }
  }
}
