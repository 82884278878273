$spacings: (
  '1': 0.25rem,
  '2': 0.5rem,
  '3': 0.75rem,
  '4': 1rem,
  '5': 1.25rem,
  '6': 1.50rem,
  '7': 1.75rem,
  '8': 2rem,
);

@each $name, $space in $spacings {
  .p-#{$name} {
    padding-left: $space;
    padding-right: $space;
    padding-top: $space;
    padding-bottom: $space;
  }

  .px-#{$name} {
    padding-left: $space;
    padding-right: $space;
  }
  .py-#{$name} {
    padding-top: $space;
    padding-bottom: $space;
  }
  .pr-#{$name} {
    padding-right: $space;
  }
  .pl-#{$name} {
    padding-left: $space;
  }
  .pt-#{$name} {
    padding-top: $space;
  }
  .pb-#{$name} {
    padding-bottom: $space;
  }
  .m-#{$name} {
    margin-left: $space;
    margin-right: $space;
    margin-top: $space;
    margin-bottom: $space;
  }

  .mx-#{$name} {
    margin-left: $space;
    margin-right: $space;
  }
  .my-#{$name} {
    margin-bottom: $space;
    margin-top: $space;
  }
  .mr-#{$name} {
    margin-right: $space;
  }
  .ml-#{$name} {
    margin-left: $space;
  }
  .mt-#{$name} {
    margin-top: $space;
  }
  .mb-#{$name} {
    margin-bottom: $space;
  }
}
