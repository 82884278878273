@use './typography.scss';

.display-3xl {
  @include typography.display-3xl;
}

.display-2xl {
  @include typography.display-2xl;
}

.display-xl {
  @include typography.display-xl;
}

.display-lg {
  @include typography.display-lg;
}

.display-md {
  @include typography.display-md;
}

.display-sm {
  @include typography.display-sm;
}

.display-xs {
  @include typography.display-sm;
}

.heading1 {
  @include typography.heading1;
}

.heading1-5 {
  @include typography.heading1-5;
}

.heading2 {
  @include typography.heading2;
}

.heading3 {
  @include typography.heading3;
}

.heading4 {
  @include typography.heading4;
}

.heading5 {
  @include typography.heading5;
}

.heading6 {
  @include typography.heading6;
}

.heading7 {
  @include typography.heading7;
}

.heading9 {
  @include typography.heading9;
}

.chat-text {
  @include typography.chat-text;
}

.chat-text-italic {
  @include typography.chat-text-italic;
}

.chat-text-bold {
  @include typography.chat-text-bold;
}

.info {
  @include typography.info;
}

.info-italic {
  @include typography.info-italic;
}

.info-medium {
  @include typography.info-medium;
}

.caption {
  @include typography.caption;
}

.heading10-label {
  @include typography.heading10-label;
}

.heading10-bold {
  @include typography.heading10-bold;
}

.hero-subhead {
  @include typography.hero-subhead;
}

.hero-descriptor {
  @include typography.hero-descriptor;
}

.hero-descriptor-semibold {
  @include typography.hero-descriptor-semibold;
}

.hero-descriptor-sm {
  @include typography.hero-descriptor-sm;
}

.sans-serif {
  @include typography.sans-serif;
}


.text-center {
  text-align: center;
}
