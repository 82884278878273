.icon {
  width: 18px; 
  height: 18px;
  color: green;

}

.icon2x {
  width: 42px; 
  height: 42px;
  color: green;

}
